<template>
    <section class="page-login"
             :class="{tablet: mq.isLandscape || (mq.mdPlus && !mq.xlPlus)}">
        <UnauthenticatedSidebar v-if="mq.mdMinus && !mq.isLandscape">
            <LoginForm
                :error="error"
                :message="message"
            />
        </UnauthenticatedSidebar>
        <UnauthenticatedSidebar v-else />
        <main v-if="!mq.mdMinus || mq.isLandscape"
              :class="{landscape: mq.mdMinus, tablet: mq.isLandscape || (mq.mdPlus && !mq.xlPlus)}">
            <LoginForm 
                :error="error"
                :message="message"
            />
        </main>
    </section>
</template>

<script setup>
import UnauthenticatedSidebar from '@/Components/Unauthenticated/Sidebar.vue';
import LoginForm from '@/Components/Unauthenticated/LoginForm.vue';
import { useMq } from 'vue3-mq';
const mq = useMq();

defineProps({
    message: {
        type: String,
        required: false,
        default: '',
    },
    error: {
        type: String,
        required: false,
        default: '',
    },
});
</script>

<style lang="scss" scoped>
.page-login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    background: var(--app-color-white);

    &.tablet {
        // grid-template-columns: 0.4fr auto;
    }

    & main {
        padding: 50px;
        align-self: center;
        display: grid;
        gap: 25px;
    }
    & main.tablet {
        padding: 25px;
        gap: 0px;
        max-height: 100vh;
    }
    & main.tablet.landscape {
        padding: 10px;
    }
}
</style>
