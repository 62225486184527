<template>
    <div class="error message-padding" v-if="error">
        {{ trans('error.E' + error) }}
    </div>
    <div class="message message-padding" v-if="message">
        {{ message }}
    </div>

    <div class="header">
        <header :class="{mobile: mq.mdMinus}">
            <div>
                <h1>{{ trans('login.login') }}</h1>
                <h2>{{ trans('login.doit') }}</h2>
            </div>
            <ILanguageMenu 
                triggerClass="login"
            />
        </header>
    </div>

    <form
        autocomplete="off"
        :class="{form: true, mobile: mq.mdMinus}"
        @submit.prevent="submitForm"
    >
        <IBox>
            <input
                type="submit"
                hidden
            />
            <IInputField
                :label="trans('login.username')"
                :required="true"
                :error="loginForm.errors.username"
            >
                <input
                    v-model="loginForm.username"
                    type="text"
                    placeholder="Max_Mustermann_abcde"
                    required
                />
            </IInputField>
            <IInputField
                :label="trans('login.password')"
                :required="true"
                :error="loginForm.errors.password"
            >
                <Password v-model="loginForm.password"
                          toggleMask
                          :feedback="false"
                          autocomplete="off"
                          required
                          hideIcon="pi pi-eye-slash"
                >
                    <template #showicon="{ onClick }">
                        <i class="pi pi-eye" @click="onClick" />
                    </template>
                </Password>
            </IInputField>
            <IInputField :required="true">
                <a href="javascript:void(0)" @click="handleForgotPassword">
                    {{ trans('login.password_forgotten') }}
                </a>
            </IInputField>
        </IBox>
        <div class="actions">
            <IButton
                icon="login"
                :label="trans('login.login')"
                @click="submitForm"
            />
        </div>
    </form>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { watch } from 'vue';
import { useMq } from 'vue3-mq';
import { useToast } from 'primevue/usetoast';
import { trans } from 'matice';
import Password from 'primevue/password';
import ILanguageMenu from '@/Components/Common/ILanguageMenu.vue';

const mq = useMq();
const toast = useToast();

defineProps({
    message: {
        type: String,
        required: false,
        default: '',
    },
    error: {
        type: String,
        required: false,
        default: '',
    },
});

const loginForm = useForm({
    username: '',
    password: '',
});

const submitForm = () => {
    loginForm.post(route('login'), {
        onFinish: () => loginForm.reset('password'),
        onError: (error) => {
            if(error.username) {
                toast.add({
                    severity: 'error',
                    detail: error.username,
                    summary:  trans('global.error'),
                    sticky: true,
                });
            }
        },
        onSuccess: () => {
            toast.add({
                severity: 'success',
                detail: trans('auth.logged_in'),
                summary:  trans('global.success'),
                life: 4000,
            });
        },
    });
};

const handleForgotPassword = () => {
    document.location.href = route('reset-password');
};

watch(() => loginForm.username, () => {
    loginForm.clearErrors();
});

</script>
<style lang="scss" scoped>
.message-padding {
    padding-left: 25px;
    padding-bottom: 10px;
}
.header {
    padding: 0 25px;
    
    header {
        display: grid;
        grid-template-columns: 1fr auto;
    }
}
.message {
    color: var(--app-color-blue);
}
form.mobile, .header:has(.mobile) {
    max-width: calc(100vw - 16px);
}
</style>
